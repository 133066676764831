import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window

  return (
    <Layout isHeaderTransparent>
      {browser && (
        <>
          <SEO title="404: Not found" />
          <Helmet>
            <title>404 | Billsby</title>
          </Helmet>

          <div className="not-found">
            <div className="container">
              <img src={require('../images/404.png')} srcSet={require('../images/404@2x.png')} alt="404" />
              <h1>404: There's nothing to see here</h1>
              <Link to="/" className="btn-white">Go home</Link>
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

export default NotFoundPage